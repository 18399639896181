<template>
  <div>
    <v-btn class="follow-btn" color="secondary" v-if="isFollowing" @click="unfollow"
      >Following</v-btn
    >
    <v-btn class="follow-btn" color="secondary" v-else @click="follow">Follow</v-btn>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "follow-button",
  props: ["userid"],
  data() {
    return {
      isFollowing: false,
    };
  },
  async mounted() {
    this.isFollowing = await this.getIsFollowing({
      followeeId: this.userid,
      followerId: this.username,
    });
  },
  computed: {
    ...mapState([
      'currentUser',
    ]),
    username() {
      if (this.currentUser !== null) {
        return this.currentUser.username;
      } else {
        return "";
      }
    }
  },
  methods: {
    async follow() {
      const res = null

      if (!res.data.createFollowRelationship.errors) {
        this.isFollowing = true;
      }
    },
    async unfollow() {
      const res = null

      if (!res.data.deleteFollowRelationship.errors) {
        this.isFollowing = false;
      }
    },
    async getIsFollowing({ followeeId, followerId }) {
      console.log(followeeId, followerId);
      const res = ""

      return res.data.getFollowRelationship !== null;
    },
  },
};
</script>

<style scoped>
.follow-btn {
  width: 256px;
}
</style>