import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import light from './themes/light'
import dark from './themes/dark'

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    dark: true,
    themes: {
      dark,
      light,
    },
    options: {
      customProperties: true,
    },
  },
})

