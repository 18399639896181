<template>
  <div>
    <v-row justify="center" align="center">
      <v-col justify="center" align="center">
        <h1>Activity Page under contruction</h1>
        <v-icon large>mdi-laptop</v-icon>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "activity",
};
</script>

<style scoped>
</style>