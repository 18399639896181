
<template>
  <div v-if="!loading" color="primary">
    <div>
      <v-row v-if="currentPostState.deleted !== true">
        <v-col cols="2" align-self="center" class="avatar-column">
          <v-avatar class="rounded-xl" tile size="100%" color="grey">
            <v-img src="@/assets/default_avatar.png"></v-img>
          </v-avatar>
        </v-col>
        <v-col cols="9">
          <v-row>
            <v-col align="start" justify="start" class="title-owner" cols="9">
              <div class="font-weight-bold post-title">
                {{ currentPostState.title }}
              </div>
              <div class="link-to-profile">
                <router-link
                  :to="{
                    name: 'Profile',
                    params: { userid: currentPostState.owner },
                  }"
                  class="grey--text text-subtitle1"
                >
                  @{{ currentPostState.owner }}
                </router-link>
              </div>
            </v-col>
            <template v-if="currentPostState.owner === currentUser.username">
              <v-col align="end" justify="end" class="menu" cols="1">
                <v-menu top :offset-y="offset">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn color="primary" v-bind="attrs" v-on="on">
                      <v-icon>mdi-dots-vertical</v-icon>
                    </v-btn>
                  </template>

                  <v-list>
                    <v-list-item @click="deletePostById(currentPostState.id)">
                      <!-- <v-list-item> -->
                      <v-list-item-title>
                        <v-icon>mdi-delete</v-icon>
                        Delete
                      </v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="copyShareLink()">
                    <!-- <v-list-item> -->
                    <v-list-item-title>
                      <v-icon>mdi-share</v-icon>
                      Share
                    </v-list-item-title>
                  </v-list-item>
                  </v-list>
                </v-menu>
              </v-col>
            </template>
          </v-row>
          <v-row justify="space-between">
            <v-col cols="10" align-self="center">
              <waveform :id="postid" :bus="bus" />
            </v-col>
            <v-col cols="2" align-self="center">
              <play-pause-button :playing="playing" v-on:toggle="togglePlay" />

              <v-snackbar v-model="error" :timeout="errorTimeout">
                {{ errorText }}

                <template v-slot:action="{ attrs }">
                  <v-btn
                    color="pink"
                    text
                    v-bind="attrs"
                    @click="error = false"
                  >
                    Close
                  </v-btn>
                </template>
              </v-snackbar>
            </v-col>
          </v-row>
          <v-row>
            <v-col align="left" justify="left" cols="2">
              <span class="track-info"> {{ timeSincePosting }}</span>
            </v-col>
            <v-spacer />
            <v-col align="start" justify="start" cols="3">
              <span class="button-action track-info">
                <v-icon
                  color="accent"
                  medium
                  @click="
                    downloadAudio({
                      sourceUrl: sourceUrl,
                      post: currentPostState,
                    })
                  "
                >
                  mdi-download
                </v-icon>
                <span>{{ currentPostState.downloads }} downloads</span>
              </span>
            </v-col>
            <v-col align="start" justify="start" cols="3">
              <like-button
                v-bind:likesCount="currentPostState.likes"
                v-bind:postId="currentPostState.id"
                v-bind:showCount="true"
              />
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row class="mt-auto mb-auto" v-else>
        <v-col>
          <v-row justify="center" align="center"> Post Unavailable </v-row>
        </v-col>
      </v-row>
    </div>
    <hr />
    <div>
      <v-row justify="center" align="center">
        <v-col justify="center" align="center">
          <p>Commenting coming soon</p>
          <v-icon large>mdi-laptop</v-icon>
        </v-col>
      </v-row>
    </div>
  </div>
  <div v-else>
    <v-progress-circular />
  </div>
</template>

<script>
import { SET_CURRENT_CLIP } from "../state/mutation-types";
import { mapActions, mapState } from "vuex";

import Vue from "vue";
import moment from "moment";

import LikeButton from "../components/Buttons/LikeButton";
import PlayPauseButton from "../components/Buttons/PlayPauseButton";
import Waveform from "../components/Waveform";

export default {
  name: "post",
  components: {
    LikeButton,
    PlayPauseButton,
    Waveform,
  },
  data() {
    return {
      loading: true,
      postid: this.$route.params.postid,
      currentPostState: null,
      offset: true,
      playing: false,
      sourceUrl: "",
      error: false,
      errorText: "Error playing clip",
      errorTimeout: 2000,
      updateSubscription: null,
      seek: 0,
      updateSeek: null,
      progress: 0,
      bus: new Vue({}), // Event bus for information to child components
    };
  },
  async mounted() {
    this.loading = true;

    this.currentPostState = {};
    this.loading = false;

    this.subscribeToUpdates();
    this.subscribeToAudioState();

    this.sourceUrl = ""
    this.bus.$emit("load", this.sourceUrl); // Load waveform
  },
  beforeUnmount() {
    if (this.playing) {
      this.pauseLoop();
    }
    this.unsubscribeFromUpdates();
    this.unsubscribeFromAudioState();
  },
  computed: {
    ...mapState(["currentUser"]),
    timeSincePosting() {
      return moment(this.currentPostState.timestamp).fromNow();
    },
  },
  methods: {
    ...mapActions(["deletePostById", "downloadAudio"]),
    subscribeToUpdates() {
      // Subscribe to updates on posts
      console.log("subscribed to updates of post");
    },
    unsubscribeFromUpdates() {
      this.updateSubscription.unsubscribe();
    },
    subscribeToAudioState() {
      this.unsubscribeFromAudioState = this.$store.subscribe((mutation) => {
        if (
          this.playing &&
          mutation.type === SET_CURRENT_CLIP &&
          mutation.payload.currentPlayingPostId !== this.currentPostState.id
        ) {
          this.pauseLoop();
        }
      });
    },
    unsubscribeFromAudioState() {
      // you may call unsubscribe to stop the subscription
      this.unsubscribeFromAudioState();
    },
    togglePlay() {
      if (this.playing) {
        this.pauseLoop();
      } else {
        this.playLoop();
      }
    },
    playLoop() {
      this.$store.commit("SET_CURRENT_CLIP", {
        currentClip: null,
        currentPlayingPostId: this.currentPostState.id,
      });

      this.playing = true;
      this.bus.$emit("play");
      this.$emit("play");
    },
    pauseLoop() {
      this.playing = false;
      this.bus.$emit("pause");
      this.$emit("pause");
    },
    stopLoop() {
      this.playing = false;
      this.bus.$emit("pause");
      this.$emit("stop");
    },
    copyShareLink() {

      //the text that is to be copied to the clipboard
     const shareLink = location.href;
      console.log("Share link " + shareLink);
 
     //create our hidden div element
     let hiddenCopy = document.createElement('div');
     //set the innerHTML of the div
     hiddenCopy.innerHTML = shareLink;
     //set the position to be absolute and off the screen
     hiddenCopy.style.position = 'absolute';
     hiddenCopy.style.left = '-9999px';
 
     //check and see if the user had a text selection range
     let currentRange;
     if(document.getSelection().rangeCount > 0)
     {
          //the user has a text selection range, store it
          currentRange = document.getSelection().getRangeAt(0);
          //remove the current selection
          window.getSelection().removeRange(currentRange);
     }
     else
     {
          //they didn't have anything selected
          currentRange = false;
     }
 
     //append the div to the body
     document.body.appendChild(hiddenCopy);
     //create a selection range
     let CopyRange = document.createRange();
     //set the copy range to be the hidden div
     CopyRange.selectNode(hiddenCopy);
     //add the copy range
     window.getSelection().addRange(CopyRange);
 
     //since not all browsers support this, use a try block
     try
     {
          //copy the text
          document.execCommand('copy');
     }
     catch(err)
     {
          window.alert("Your Browser Doesn't support this! Error : " + err);
     }
     //remove the selection range (Chrome throws a warning if we don't.)
     window.getSelection().removeRange(CopyRange);
     //remove the hidden div
     document.body.removeChild(hiddenCopy);
 
     //return the old selection range
     if(currentRange)
     {
          window.getSelection().addRange(currentRange);
     }
    },
  },
};
</script>

<style scoped>
a {
  text-decoration: none;
}

.card {
  width: 100%;
  margin-bottom: 5%;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  padding-left: 1em;
  /* border-radius: 25px !important; */
  border-width: 0.7px !important;
}

.button-action {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.track-info {
  font-size: 8pt;
}
#artist {
  margin-top: -10px;
}

.v-application .primary {
  border-color: var(--v-accent-base) !important;
}

.link-to-profile:hover {
  text-decoration: underline;
}

.post-title {
  font-size: 28px;
  font-family: "Rubik", sans-serif;
}

.title-owner {
  padding-bottom: 0;
}

.avatar-column {
  padding-left: 0.3em;
}
</style>