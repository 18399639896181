<template>
  <span @click="showDialog">
    <slot></slot>
    <v-dialog v-model="dialog" max-width="35%" class="dialog">
      <upload-card v-on:uploaded="hideDialog()" />
    </v-dialog>
  </span>
</template>

<script>
import UploadCard from './Cards/UploadCard.vue';

export default {
  components: { UploadCard },
  name: "UploadTrack",
  data() {
    return {
      dialog: false
    };
  },
  methods: {
    showDialog() {
      this.dialog = true;
    },
    hideDialog() {
      console.log("hiding")
      this.dialog = false;
    }
  }
};
</script>

<style scoped>
.dialog {
  margin: 0;
  box-shadow: none;
}
</style>
