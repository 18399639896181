<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>

export default {
  name: "app",
  metaInfo: {
    // if no subcomponents specify a metaInfo.title, this title will be used
    title: "In The Loop",
    // all titles will be injected into this template
    // titleTemplate: "%s | In The Loop",
    meta: [
      {
        name: "description",
        content:
          "In The Loop - The online platform tailored for producers and creators to share their loops to the world, get feedback on their music, and join the world-wide community of artists to collaborate with",
      },
      // OpenGraph data (Most widely used)
      { property: "og:title", content: "In The Loop" },
      { property: "og:site_name", content: "In The Loop" },
      // The list of types is available here: http://ogp.me/#types
      { property: "og:type", content: "website" },
      // Should the the same as your canonical link, see below.
      {
        property: "og:url",
        content: "https://intheloopstudio.com",
      },
      {
        property: "og:image",
        content: "https://intheloopstudio.com/Screenshot.png",
      },
      // Often the same as your meta description, but not always.
      {
        property: "og:description",
        content:
          "In The Loop - The online platform tailored for producers and creators to share their loops to the world, get feedback on their music, and join the world-wide community of artists to collaborate with",
      },

      // Twitter card
      { name: "twitter:card", content: "summary" },
      {
        name: "twitter:site",
        content: "https://intheloopstudio.com",
      },
      { name: "twitter:title", content: "In The Loop" },
      {
        name: "twitter:description",
        content:
          "In The Loop - The online platform tailored for producers and creators to share their loops to the world, get feedback on their music, and join the world-wide community of artists to collaborate with",
      },
      // Your twitter handle, if you have one.
      { name: "twitter:creator", content: "@intheloop" },
      {
        name: "twitter:image:src",
        content: "https://intheloopstudio.com/Screenshot.png",
      },

      // Google / Schema.org markup:
      { itemprop: "name", content: "In The Loop" },
      {
        itemprop: "description",
        content:
          "In The Loop - The online platform tailored for producers and creators to share their loops to the world, get feedback on their music, and join the world-wide community of artists to collaborate with",
      },
      {
        itemprop: "image",
        content: "https://intheloopstudio.com/Screenshot.png",
      },
    ],
    link: [
      {
        rel: "canonical",
        href: "https://intheloopstudio.com",
      },
    ],
  },
};
</script>

<style scoped>
</style>