<template>
  <v-container>
    <v-card class="auth-card" @submit.prevent="_logIn">
      <h1>Login to In The Loop</h1>

      <form class="login-form">
        <v-text-field
          outlined
          color="accent"
          label="email"
          :rules="emailRules"
          v-model="email"
        ></v-text-field>

        <v-text-field
          outlined
          hidden
          color="accent"
          label="password"
          :rules="passwordRules"
          v-model="password"
        ></v-text-field>

        <v-btn
          color="secondary"
          class="lighten-1 white--text"
          elevation="2"
          raised
          :disabled="loading"
          @click="_logIn"
          type="submit"
          >Login</v-btn
        >
        <div class="forgot-password-btn">Forgot Password?</div>
        <div class="create-account-btn">Create an Account</div>
      </form>
      <div id="customGoogleBtn" class="customGPlusSignIn">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          viewBox="0 0 48 48"
        >
          <defs>
            <path
              id="a"
              d="M44.5 20H24v8.5h11.8C34.7 33.9 30.1 37 24 37c-7.2 0-13-5.8-13-13s5.8-13 13-13c3.1 0 5.9 1.1 8.1 2.9l6.4-6.4C34.6 4.1 29.6 2 24 2 11.8 2 2 11.8 2 24s9.8 22 22 22c11 0 21-8 21-22 0-1.3-.2-2.7-.5-4z"
            />
          </defs>
          <clipPath id="b"><use xlink:href="#a" overflow="visible" /></clipPath>
          <path clip-path="url(#b)" fill="#FBBC05" d="M0 37V11l17 13z" />
          <path
            clip-path="url(#b)"
            fill="#EA4335"
            d="M0 11l17 13 7-6.1L48 14V0H0z"
          />
          <path
            clip-path="url(#b)"
            fill="#34A853"
            d="M0 37l30-23 7.9 1L48 0v48H0z"
          />
          <path
            clip-path="url(#b)"
            fill="#4285F4"
            d="M48 48L17 24l-4-3 35-10z"
          />
        </svg>
        <span class="buttonText">Google</span>
      </div>
    </v-card>
  </v-container>
</template>

<script>
import { mapState, mapActions } from "vuex";

export default {
  name: "login",
  data() {
    return {
      email: "",
      password: "",
    };
  },
  computed: {
    ...mapState(["logInError", "signUpError", "recoverPasswordError"]),
  },
  methods: {
    ...mapActions(["logIn", "logInWithGoogle", "signUp", "recoverPassword"]),
    _logIn() {
      this.logIn();
    },
    _logInWithGoogle() {
      this.logInWithGoogle();
    },
    _signUp() {
      this.signUp();
    },
    _recoverPassword() {
      this.recoverPassword();
    },
  },
};
</script>

<style scoped>
.auth-card {
  padding: 3em;
}

.forgot-password-btn {
}

.create-account-btn {
}

#customGoogleBtn {
  display: inline-block;
  background: white;
  color: #444;
  width: 190px;
  border-radius: 5px;
  border: thin solid #888;
  box-shadow: 1px 1px 1px grey;
  white-space: nowrap;
}
#customogleBtn:hover {
  cursor: pointer;
}
span.label {
  font-family: serif;
  font-weight: normal;
}
span.icon {
  background: url("/identity/sign-in/g-normal.png") transparent 5px 50%
    no-repeat;
  display: inline-block;
  vertical-align: middle;
  width: 42px;
  height: 42px;
}
span.buttonText {
  display: inline-block;
  vertical-align: middle;
  padding-left: 42px;
  padding-right: 42px;
  font-size: 14px;
  font-weight: bold;
  /* Use the Roboto font that is loaded in the <head> */
  font-family: "Roboto", sans-serif;
}
</style>