<template>
  <div>
    <v-container>
      <v-row align="start">
        <v-col cols="3">
          <template v-if="pageLoading">
            <v-progress-circular></v-progress-circular>
          </template>
          <template v-else class="profile-info">
            <v-avatar class="profile-pic rounded" color="grey" size="256" tile>
              <v-img src="@/assets/default_avatar.png"></v-img>
            </v-avatar>
            <v-btn disabled class="edit-photo-btn" color="secondary">Change Photo</v-btn>
            <v-row justify="start">
              <v-col>@{{ currentUser.username }} </v-col>
            </v-row>
            <v-row>
              <v-col> {{ currentUser.attributes.email }} </v-col>
            </v-row>
          </template>
        </v-col>
        <v-col>
          <form class="upload-form">
            <v-text-field
              outlined
              disabled
              label="Username"
              :rules="usernameRules"
              v-model="username"
              color="accent"
            ></v-text-field>
            <v-textarea
              outlined
              disabled
              label="Bio"
              :rules="bioRules"
              v-model="bio"
              color="accent"
            >
              <template v-slot:label>
                <div>Bio</div>
              </template>
            </v-textarea>
            <v-text-field
              outlined
              disabled
              label="Website"
              color="accent"
            ></v-text-field>
            <v-text-field
              outlined
              disabled
              label="Twitter"
              color="accent"
            ></v-text-field>
            <v-btn
              disabled
              color="secondary"
              class="lighten-1 white--text"
              elevation="2"
              raised
              @click="updateCurrentUserMetadata"
              >Update</v-btn
            >
          </form>

          <v-spacer />

          <v-row class="theme-switch">
            <v-icon color="accent" class="theme-switch-icon"
              >mdi-theme-light-dark</v-icon
            >
            <v-switch
              v-model="theme"
              inset
              label="Theme"
              color="secondary"
            ></v-switch>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
  name: "settings",
  data() {
    return {
      theme: this.$vuetify.theme.dark,
      username: "",
      usernameRules: [],
      bio: "",
      bioRules: [],
      // avatarLink: "",
      // twitterHandle: "",
      // spotifyLink: "",
      // soundcloudLink: "",
      // website: "",
    };
  },
  mounted() {
    // Check if UserMetadata exists for that user already
    // if so: user current metadata as placeholders in the fields
    // otherwise: leave the palceholders blank
  },
  computed: {
    ...mapState([
      "currentUser",
      "currentUserMetadata"
    ]),
    pageLoading() {
      return this.currentUser === null;
    },
  },
  methods: {
    ...mapActions([
      "getCurrentUserMetadata",
      "updateCurrentUserMetadata",
    ]),
  },
  watch: {
    theme() {
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
      localStorage.setItem("dark_theme", this.$vuetify.theme.dark.toString());
    },
  },
};
</script>

<style scoped>
.theme-switch-icon {
  margin-right: 1em;
}

.theme-switch {
  margin-top: 1em;
}

.edit-photo-btn {
  width: 256px;
  margin-top: 1em;
  margin-bottom: 1em;
}
</style>