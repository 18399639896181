<template>
  <div>
    <home />
    <about />
    <affiliate />
    <pricing />
  </div>
</template>

<script>
import Home from "../components/LandingSections/Home";
import About from "../components/LandingSections/About";
import Pricing from "../components/LandingSections/Pricing";
import Affiliate from "../components/LandingSections/Affiliate";

export default {
  name: "getting-started",
  components: {
    Home,
    About,
    Pricing,
    Affiliate,
  },
  data() {
    return {};
  },
};
</script>

<style scoped>

</style>
