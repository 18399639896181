<template>
  <v-container class="profile-container">
    <v-row justify="space-between" align="start">
      <v-col cols="2">
        <div class="profile-info">
          <v-avatar class="profile-pic rounded" color="grey" size="256" tile>
            <v-img src="@/assets/default_avatar.png"></v-img>
          </v-avatar>
          <v-row>
            <v-col>
              <template v-if="pageLoading">
                <v-progress-circular></v-progress-circular>
              </template>
              <follow-button
                v-else-if="userid !== currentUser.username"
                v-bind:userid="userid"
              />
            </v-col>
          </v-row>
          <v-row justify="space-between" align="center">
            <v-col>
              <h1>{{ userid }}</h1>
            </v-col>
            <v-col>
              <router-link to="/settings">
                <v-col justify="center" align="center">
                  <v-icon>mdi-cog</v-icon>
                </v-col>
              </router-link>
            </v-col>
          </v-row>
          <v-row justify="start">
            <v-col> <v-icon>mdi-at</v-icon> </v-col>
            <v-col> {{ userid }} </v-col>
          </v-row>
          <v-row v-if="owner">
            <v-col> <v-icon>mdi-email</v-icon> </v-col>
            <v-col> {{ currentUser.attributes.email }} </v-col>
          </v-row>
        </div>
      </v-col>

      <v-col cols="8" class="posts">
        <div v-for="post in filteredPosts" :key="post.id">
            <large-audio-card v-bind:post="post" />
        </div>
        <div align="center" v-if="moreData && filteredPosts.length !== 0">
          <v-btn class="more-button" color="secondary" @click="listPosts">
            More
          </v-btn>
        </div>
        <div class="end-of-list" align="center" v-else>End of List</div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState } from "vuex";

import LargeAudioCard from "../components/Cards/LargeAudioCard.vue";
import FollowButton from "../components/Buttons/FollowButton.vue";

export default {
  name: "profile",
  components: {
    LargeAudioCard,
    FollowButton,
  },
  async mounted() {
    await this.listPosts();

    this.subscribeToTimeline();

  },
  unmount() {
    this.unsubscribeFromTimeline();
  },
  data() {
    return {
      userid: this.$route.params.userid,
      posts: [],
      currentClip: null,
      isFollowing: false,
      nextTokens: [],
      moreData: true,
    };
  },
  computed: {
    ...mapState([
      'currentUser',
    ]),
    owner() {
      return this.currentUser.username === this.userid
    },
    pageLoading() {
      return this.currentUser === null;
    },
    // Temperary Fix to make sure there are no duplicates and no deleted posts
    filteredPosts() {
      return this.posts.filter((schema, index, self) => {
        return (index ===
          self.findIndex((obj) => {
            return obj.id === schema.id;
          })) && schema.deleted !== true;
      });
    },
  },
  methods: {
    subscribeToTimeline() {
      // Subscribe to creation of Posts
      console.log("Subscribing to timeline");
    },
    unsubscribeFromTimeline() {
      this.timelineSubscription.unsubscribe();
    },
    async listPosts() {
      const res = null

      if (
        this.nextTokens.includes(res.data.listPostsBySpecificOwner.nextToken)
      ) {
        this.moreData = false;
        return;
      }

      this.nextTokens.unshift(res.data.listPostsBySpecificOwner.nextToken);

      this.posts = this.posts.concat(res.data.listPostsBySpecificOwner.items);
    },
  },
};
</script>

<style scoped>
.v-application a {
  text-decoration: none;
}

.profile-container {
  padding-left: 1em;
  margin-left: 1em;
}

.profile-pic {
  margin-bottom: 1em;
}

.profile-info {
  position: fixed;
}

.posts {
  /* margin-left: 30%; */
}

.more-button {
  margin-bottom: 5em;
}

.end-of-list {
  opacity: 0.65;
}
</style>
