
import colors from 'vuetify/lib/util/colors'

export default {
    primary: {
        base: "#FFF",
        lighten5: "#FFF",
        lighten4: "#FFF",
        lighten3: "#FFF",
        lighten2: "#FFF",
        lighten1: "#FFF",
        darken1: "#FFF",
        darken2: "#FFF",
        darken3: "#FFF",
        darken4: "#FFF",
    },
    secondary: "#6200EE",
    accent: "#000",
    error: colors.red.accent3,
    background: "#FFF",
}