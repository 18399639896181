import firebase from "firebase"
// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyBIHwGYfS7MGREOR4nSTYJxZPLXNApTJ3M",
    authDomain: "in-the-loop-306520.firebaseapp.com",
    projectId: "in-the-loop-306520",
    storageBucket: "in-the-loop-306520.appspot.com",
    messagingSenderId: "269420857313",
    appId: "1:269420857313:web:1ace984d27362ddcf7f4a0",
    measurementId: "G-D8EFYQBB2Q"
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);
firebase.analytics();

export const db = firebase.firestore();