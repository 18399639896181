<template>
  <div>
    <v-navigation-drawer v-model="drawer" app temporary right color="primary">
      <v-list>
        <v-list-item>
          <v-list-item-avatar>
            <img src="@/assets/logo.svg" alt="Logo" height="32" width="32" />
          </v-list-item-avatar>
          <v-list-item-content>
            <router-link to="/">
              <v-toolbar-title color="secondary">In The Loop</v-toolbar-title>
            </router-link>
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <v-divider />

      <v-list dense>
        <v-list-item
          v-for="([icon, text, link], i) in items"
          :key="i"
          link
          @click="$vuetify.goTo(link)"
        >
          <v-list-item-icon class="justify-center">
            <v-icon>{{ icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title class="subtitile-1">{{
              text
            }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <!-- <router-link to="/login"> -->
          <a href="https://nzqgw10avlu.typeform.com/to/scNWQNtg">
            <v-list-item-icon>
              <v-icon>mdi-account</v-icon>
            </v-list-item-icon>
            <v-list-item-content> Sign In </v-list-item-content>
          </a>
          <!-- </router-link> -->
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar
      app
      :color="color"
      :flat="flat"
      :class="{ expand: flat }"
      class="px-15"
    >
      <router-link to="/">
        <!-- <v-img src="@/assets/img/logo.png" max-width="50px" /> -->
        <v-toolbar-title color="secondary">
          <img src="@/assets/logo.svg" alt="Logo" height="32" width="32" />
          In The Loop
        </v-toolbar-title>
      </router-link>
      <v-spacer />
      <v-app-bar-nav-icon
        @click.stop="drawer = !drawer"
        class="mr-4"
        v-if="isXs"
      />
      <div v-else>
        <v-btn text @click="$vuetify.goTo('#hero')">
          <span class="mr-2">Home</span>
        </v-btn>
        <v-btn text @click="$vuetify.goTo('#about')">
          <span class="mr-2">About</span>
        </v-btn>
        <v-btn text>
          <span class="mr-2" @click="$vuetify.goTo('#pricing')">Pricing</span>
        </v-btn>
        <!-- <router-link to="/login">
          <v-btn color="secondary">
            <span class="mr-2 white--text">Sign In</span>
          </v-btn>
        </router-link> -->
        <a href='https://nzqgw10avlu.typeform.com/to/scNWQNtg'>
        <v-btn color="secondary">
          <span class="mr-2 white--text">Sign Up</span>
        </v-btn>
        </a>
      </div>
    </v-app-bar>
  </div>
</template>

<script>
export default {
  name: "unauth-bar",
  props: {
    color: String,
    flat: Boolean,
  },
  data() {
    return {
      drawer: null,
      isXs: false,
      items: [
        ["mdi-home", "Home", "#hero"],
        ["mdi-information-outline", "Features", "#features"],
        ["mdi-information-outline", "About", "#about"],
        ["mdi-currency-usd", "Pricing", "#pricing"],
      ],
    };
  },
  methods: {
    onResize() {
      this.isXs = window.innerWidth < 850;
    },
  },

  watch: {
    isXs(value) {
      if (!value) {
        if (this.drawer) {
          this.drawer = false;
        }
      }
    },
  },
  mounted() {
    this.onResize();
    window.addEventListener("resize", this.onResize, { passive: true });
  },
};
</script>

<style scoped>
.v-application a {
  text-decoration: none;
  color: var(--v-accent-base) !important;
}

.v-toolbar {
  transition: 0.6s;
}

.expand {
  height: 80px !important;
  padding-top: 10px;
}
</style>