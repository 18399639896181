
<template>
  <span class="button-action track-info">
    <v-icon v-if="isLiking" color="red" medium @click="unlikeAudio">mdi-heart</v-icon>
    <v-icon v-else color="accent" medium @click="likeAudio">mdi-heart</v-icon>

    <span v-if="showCount">
      {{ likesCount }} likes
    </span>
  </span>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "like-button",
  props: ["postId", "likesCount", "showCount"],
  async mounted() {
    this.isLiking = await this.getIsLiking();
  },
  data() {
      return {
          isLiking: false,
      };
  },
  computed: {
    ...mapState([
      'currentUser',
    ]),
    username() {
      if (this.currentUser !== null) {
        return this.currentUser.username;
      } else {
        return "";
      }
    }
  },
  methods: {
    async likeAudio() {
      const res1 = null

      const res2 = null

      if (
        !res1.data.createLikesRelationship.errors &&
        !res2.data.updatePost.errors
      ) {
        this.isLiking = true;
      }
    },
    async unlikeAudio() {

      const res1 = null

      const res2 = null

      if (
        !res1.data.deleteLikesRelationship.errors &&
        !res2.data.updatePost.errors
      ) {
        this.isLiking = false;
      }
    },
    async getIsLiking() {
      const res = null;
      return res.data.getLikesRelationship !== null;
    },
  },
};
</script>