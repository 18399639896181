<template>
  <v-btn
    icon
    height="40px"
    right
    width="40px"
    v-if="!playing"
    @click="toggleBtn"
  >
    <v-icon large color="accent">mdi-play</v-icon>
  </v-btn>
  <v-btn fab icon height="40px" right width="40px" v-else @click="toggleBtn">
    <v-icon large color="accent">mdi-pause</v-icon>
  </v-btn>
</template>

<script>
export default {
  name: "play-pause-button",
  props: ["playing"],
  emits: ["toggle"],
  methods: {
    toggleBtn() {
      this.$emit("toggle");
    },
  },
};
</script>

<style scoped>
</style>