<template>
  <v-app app>
    <auth-bar />
    <!-- <v-banner single-line :sticky="true">
        <v-icon slot="icon" color="warning" size="36">
          mdi-alert-circle
        </v-icon>
        This website is still under construction so your account could be
        deleted and any data uploaded could be lost
        <template v-slot:actions="{ dismiss }">
          <v-btn text color="indigo" @click="dismiss">
            Got It
          </v-btn>
        </template>
      </v-banner> -->
    <v-row justify="start">
      <v-col cols="12">
        <v-main>
          <v-container fluid class="main-container">
            <router-view />
          </v-container>
        </v-main>
      </v-col>
    </v-row>
    <!-- <app-footer></app-footer> -->
  </v-app>
</template>

<script>
import { mapActions } from "vuex";
// import AppFooter from "../components/AppFooter";
import AuthBar from "../components/Navigation/AuthBar.vue";

export default {
  name: "home",
  components: {
    // AppFooter,
    AuthBar,
  },
  mounted() {
    this.setAuth();
    this.setTheme();
  },
  data() {
    return {};
  },
  methods: {
    ...mapActions([
      "setAuth",
    ]),
    setTheme() {
      const theme = localStorage.getItem("dark_theme");
      if (theme) {
        if (theme === "true") {
          this.$vuetify.theme.dark = true;
        } else {
          this.$vuetify.theme.dark = false;
        }
      } else if (
        window.matchMedia &&
        window.matchMedia("(prefers-color-scheme: dark)").matches
      ) {
        this.$vuetify.theme.dark = true;
        localStorage.setItem("dark_theme", this.$vuetify.theme.dark.toString());
      }
    },
  },
};
</script>

<style scoped>
#app {
  /* background: linear-gradient(301.48deg, #6E02C2 -9.09%, rgba(0, 0, 0, 0) 74.44%), var(--v-background-base); */
  background-color: var(--v-primary-base);
  overscroll-behavior: none;
}

.main-container {
  margin-top: 4em;
}
</style>
