<template>
  <section id="affiliate">
    <v-container fluid>
      <v-row align="center" justify="center">
        <v-col cols="10">
          <v-row align="center" justify="center">
            <v-col sm="4" class="hidden-xs-only">
              <v-img src="@/assets/img/ill2.svg" class="d-block ml-auto mr-auto" max-width="350px" />
            </v-col>
            <v-col cols="12" sm="8" class="white--text text-left">
              <h1 class="font-weight-light display-2 mb-2">Become an Affiliate</h1>
              <h1 class="font-weight-light">
                The In The Loop Affiliate Program puts qualified creators 
                one step closer toward the dream of making a living from their passions. 
                In The Loop Affiliates can start earning income on their loops while 
                they build their audience.
              </h1>
              <v-btn rounded outlined href="https://nzqgw10avlu.typeform.com/to/Ondtg1Wj" target="_blank" large color="white" class="mt-4">
                <v-icon class="mr-2">
                  mdi-information
                </v-icon>
                Join Waitlist
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script>
export default {
    name: "affiliate",
}
</script>

<style scoped>
#affiliate {
  background-image: url("~@/assets/img/bgDownload.jpg");
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 500px;
}
#affiliate .container,
#affiliate .row {
  height: 100%;
}
</style>