<template>
  <span id="nav">
    <v-navigation-drawer v-model="drawer" app temporary right color="primary">
      <v-list nav dense>
        <v-list-item>
          <v-list-item-avatar>
            <img src="@/assets/logo.svg" alt="Logo" height="32" width="32"/>
          </v-list-item-avatar>
          <v-list-item-content>
            <router-link to="/">
              <v-toolbar-title color="secondary"> In The Loop </v-toolbar-title>
            </router-link>
          </v-list-item-content>
        </v-list-item>
        <v-divider />
        <v-list-item-group v-model="group" active-class="text--accent-4">
          <upload-track>
            <v-list-item>
              <v-list-item-icon>
                <v-icon> mdi-plus </v-icon>
              </v-list-item-icon>
              <v-list-item-title>New Post</v-list-item-title>
            </v-list-item>
          </upload-track>
          <router-link to="/">
            <v-list-item>
              <v-list-item-icon>
                <v-icon>mdi-home</v-icon>
              </v-list-item-icon>
              <v-list-item-title> Home </v-list-item-title>
            </v-list-item>
          </router-link>
          <router-link to="/activity">
            <v-list-item>
              <v-list-item-icon>
                <v-icon>mdi-heart</v-icon>
              </v-list-item-icon>
              <v-list-item-title> Activity </v-list-item-title>
            </v-list-item>
          </router-link>
          <router-link :to="{ name: 'Profile', params: { userid: username } }">
            <v-list-item>
              <v-list-item-icon>
                <v-icon>mdi-account</v-icon>
              </v-list-item-icon>
              <v-list-item-title> Profile </v-list-item-title>
            </v-list-item>
          </router-link>
          <v-list-item @click="signOut">
            <v-list-item-icon>
              <v-icon>mdi-exit-to-app</v-icon>
            </v-list-item-icon>
            <v-list-item-title> Sign Out </v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar fixed elevate-on-scroll color="primary">
      <router-link to="/">
        <v-toolbar-title>
          <img src="@/assets/logo.svg" alt="Logo" height="32" width="32"/>
          In The Loop 
          </v-toolbar-title>
      </router-link>

      <v-spacer />
      <v-app-bar-nav-icon
        @click.stop="drawer = !drawer"
        class="mr-4"
        v-if="isXs"
      />
      <div v-else>
        <upload-track>
          <v-btn icon>
            <v-tooltip bottom color="primary">
              <template v-slot:activator="{ on, attrs }">
                <v-icon color="accent" v-bind="attrs" v-on="on">
                  mdi-plus
                </v-icon>
              </template>
              <span>Create Post</span>
            </v-tooltip>
          </v-btn>
        </upload-track>
        <router-link to="/">
          <v-btn icon>
            <v-tooltip bottom color="primary">
              <template v-slot:activator="{ on, attrs }">
                <v-icon color="accent" v-bind="attrs" v-on="on"
                  >mdi-home</v-icon
                >
              </template>
              <span>Home</span>
            </v-tooltip>
          </v-btn>
        </router-link>
        <router-link to="/activity">
          <v-btn icon>
            <v-tooltip bottom color="primary">
              <template v-slot:activator="{ on, attrs }">
                <v-icon color="accent" v-bind="attrs" v-on="on">
                  mdi-heart
                </v-icon>
              </template>
              <span>Activity</span>
            </v-tooltip>
          </v-btn>
        </router-link>
        <v-menu bottom offset-y rounded="'b-xl'">
          <template v-slot:activator="{ attrs, on }">
            <v-btn icon v-bind="attrs" v-on="on">
              <v-tooltip bottom color="primary">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon color="accent" v-bind="attrs" v-on="on">
                    mdi-account
                    </v-icon>
                </template>
                <span>Profile</span>
              </v-tooltip>
            </v-btn>
          </template>

          <v-list color="primary">
            <v-list-item>
              <router-link
                :to="{ name: 'Profile', params: { userid: username } }"
              >
                <v-list-item-title>Profile</v-list-item-title>
              </router-link>
            </v-list-item>

            <v-list-item>
              <router-link to="/settings">
                <v-list-item-title>Settings</v-list-item-title>
              </router-link>
            </v-list-item>

            <v-list-item @click="signOut">
              <v-list-item-title>Sign Out</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
    </v-app-bar>
  </span>
</template>

<script>
import { mapState } from "vuex";

import UploadTrack from "../UploadTrack";

export default {
  name: "auth-bar",
  components: {
    UploadTrack,
  },
  data() {
    return {
      group: null,
      drawer: null,
      isXs: false,
    };
  },
  mounted() {
    this.onResize();
    window.addEventListener("resize", this.onResize, { passive: true });
  },
  computed: {
    ...mapState(["currentUser"]),
    username() {
      if (this.currentUser !== null) {
        return this.currentUser.username;
      } else {
        return "";
      }
    },
  },
  methods: {
    onResize() {
      this.isXs = window.innerWidth < 850;
    },
    async signOut() {
      // await Auth.signOut();
      this.$store.commit("SET_AUTH", null);
      this.$router.push({ path: "/landing" });
    },
  },
  watch: {
    isXs(value) {
      if (!value) {
        if (this.drawer) {
          this.drawer = false;
        }
      }
    },
  },
};
</script>

<style scoped>
.v-application a {
  text-decoration: none;
  color: var(--v-accent-base) !important;
}

.tip {
  color: var(--v-accent-base);
}
</style>
