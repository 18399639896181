var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"card rounded-xl",attrs:{"outlined":"","color":"primary"}},[(_vm.currentPostState.deleted !== true)?_c('v-row',[_c('v-col',{staticClass:"avatar-column",attrs:{"cols":"3","align-self":"center"}},[_c('v-avatar',{staticClass:"rounded-xl",attrs:{"tile":"","size":"100%","color":"grey"}},[_c('v-img',{attrs:{"src":require("@/assets/default_avatar.png")}})],1)],1),_c('v-col',{attrs:{"cols":"9"}},[_c('v-row',[_c('v-col',{staticClass:"title-owner",attrs:{"align":"start","justify":"start","cols":"9"}},[_c('div',{staticClass:"font-weight-bold post-title"},[_vm._v(" "+_vm._s(_vm.currentPostState.title)+" ")]),_c('div',{staticClass:"link-to-profile"},[_c('router-link',{staticClass:"grey--text text-subtitle1",attrs:{"to":{
                name: 'Profile',
                params: { userid: _vm.currentPostState.owner },
              }}},[_vm._v(" @"+_vm._s(_vm.currentPostState.owner)+" ")])],1)]),(_vm.currentPostState.owner === _vm.currentUser.username)?[_c('v-col',{staticClass:"menu",attrs:{"align":"end","justify":"end","cols":"1"}},[_c('v-menu',{attrs:{"top":"","offset-y":_vm.offset},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary"}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}],null,false,1940150732)},[_c('v-list',[_c('v-list-item',{on:{"click":function($event){return _vm.deletePostById(_vm.currentPostState.id)}}},[_c('v-list-item-title',[_c('v-icon',[_vm._v("mdi-delete")]),_vm._v(" Delete ")],1)],1),_c('v-list-item',{on:{"click":function($event){return _vm.copyShareLink()}}},[_c('v-list-item-title',[_c('v-icon',[_vm._v("mdi-share")]),_vm._v(" Share ")],1)],1)],1)],1)],1)]:_vm._e()],2),_c('v-row',{attrs:{"justify":"space-between"}},[_c('v-col',{attrs:{"cols":"10","align-self":"center"}},[_c('waveform',{attrs:{"id":_vm.currentPostState.id,"bus":_vm.bus}})],1),_c('v-col',{attrs:{"cols":"2","align-self":"center"}},[_c('play-pause-button',{attrs:{"playing":_vm.playing},on:{"toggle":_vm.togglePlay}}),_c('v-snackbar',{attrs:{"timeout":_vm.errorTimeout},scopedSlots:_vm._u([{key:"action",fn:function(ref){
              var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"color":"pink","text":""},on:{"click":function($event){_vm.error = false}}},'v-btn',attrs,false),[_vm._v(" Close ")])]}}],null,false,460548723),model:{value:(_vm.error),callback:function ($$v) {_vm.error=$$v},expression:"error"}},[_vm._v(" "+_vm._s(_vm.errorText)+" ")])],1)],1),_c('v-row',[_c('v-col',{attrs:{"align":"left","justify":"left","cols":"2"}},[_c('span',{staticClass:"track-info"},[_vm._v(" "+_vm._s(_vm.timeSincePosting))])]),_c('v-spacer'),_c('v-col',{attrs:{"align":"start","justify":"start","cols":"3"}},[_c('span',{staticClass:"button-action track-info"},[_c('v-icon',{attrs:{"color":"accent","medium":""},on:{"click":function($event){return _vm.$router.push({ name: 'Post', params: { postid: _vm.post.id } })}}},[_vm._v(" mdi-comment ")]),_c('span',[_vm._v("comments")])],1)]),_c('v-col',{attrs:{"align":"start","justify":"start","cols":"3"}},[_c('span',{staticClass:"button-action track-info"},[_c('v-icon',{attrs:{"color":"accent","medium":""},on:{"click":function($event){return _vm.downloadAudio({
                  sourceUrl: _vm.sourceUrl,
                  post: _vm.currentPostState,
                })}}},[_vm._v(" mdi-download ")]),_c('span',[_vm._v(_vm._s(_vm.currentPostState.downloads)+" downloads")])],1)]),_c('v-col',{attrs:{"align":"start","justify":"start","cols":"3"}},[_c('like-button',{attrs:{"likesCount":_vm.currentPostState.likes,"postId":_vm.currentPostState.id,"showCount":true}})],1)],1)],1)],1):_c('v-row',{staticClass:"mt-auto mb-auto"},[_c('v-col',[_c('v-row',{attrs:{"justify":"center","align":"center"}},[_vm._v(" Post Unavailable ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }