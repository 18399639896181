
<template>
  <div></div>
</template>

<script>
export default {
  name: "uploaded-loop-data",
  props: ["file"],
  emits: ["duration"],
  data() {
    return {
      fileReader: new FileReader(),
      audioDuration: 0.0,
    };
  },
  mounted() {
    this.startAudioDurationValidator();
  },
  methods: {
    startAudioDurationValidator() {
      let that = this; // I hate javascript

      // When the file has been succesfully read
      this.fileReader.onload = function (event) {
        // Create an instance of AudioContext
        let audioContext = new (window.AudioContext ||
          window.webkitAudioContext)();

        // Asynchronously decode audio file data contained in an ArrayBuffer.
        audioContext.decodeAudioData(event.target.result, function (buffer) {
          // Obtain the duration in seconds of the audio file (with milliseconds as well, a float value)
          that.audioDuration = buffer.duration;

          // example 12.3234 seconds
          console.log(
            "The duration of the song is: " + that.audioDuration + " seconds"
          );
          // Alternatively, just display the integer value with
          // parseInt(duration)
          // 12 seconds

          that.$emit("duration", that.audioDuration);
        });
      };

      // In case that the file couldn't be read
      this.fileReader.onerror = function (event) {
        console.error("An error ocurred reading the file: ", event);
      };
    },
  },
  watch: {
    file(newFile) {
      if (newFile) {
        // Read file as an ArrayBuffer, important !
        this.fileReader.readAsArrayBuffer(newFile);
      }
    },
  },
};
</script>

<style scoped>
</style>