
<template>
  <div class="container">
    <div :id="id.substring(0, 4)"></div>
  </div>
</template>

<script>
import WaveSurfer from "wavesurfer.js";
import CursorPlugin from 'wavesurfer.js/dist/plugin/wavesurfer.cursor.min.js';

export default {
  name: "waveform",
  props: [
      "id",
      "bus",
  ],
  data() {
    return {
      waveform: null,
    };
  },
  mounted() {
      this.waveform = WaveSurfer.create({
      container: document.querySelector(
        `[id='${this.id.substring(0, 4)}']`
      ),
      waveColor: "#D9DCFF",
      progressColor: "#6200EE",
      cursorColor: "#D9DCFF",
      barWidth: 3,
      barRadius: 3,
      cursorWidth: 1,
      height: 80,
      barGap: 3,
      plugins: [
        CursorPlugin.create({
          showTime: true,
          opacity: 1,
          zIndex: 4,
          customShowTimeStyle: {
            'background-color': "#000",
            color: "#fff",
            padding: "2px",
            'font-size': "10px",
          },
        }),
      ],
    });
    this.bus.$on("play", this.play);
    this.bus.$on("pause", this.pause);
    this.bus.$on("load", url => this.load(url));
  },
  methods: {
      play() {
          this.waveform.play();
      },
      pause() {
          this.waveform.pause();
      },
      load(sourceUrl) {
        this.waveform.load(sourceUrl);
        console.log("loaded");
      }
  },
};
</script>

<style scoped>
.container {
  position: relative;
}
</style>