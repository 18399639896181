import Vue from 'vue'
import Router from 'vue-router'

import Settings from '../views/Settings.vue'
import Activity from '../views/Activity.vue'
import Profile from '../views/Profile.vue'
import Timeline from '../views/Timeline.vue'
import Home from '../views/Home.vue';
import Post from '../views/Post.vue';

import Authenticator from '../views/Authenticator.vue';

import Landing from '../views/Landing.vue';
import GetStarted from '../views/GetStarted.vue';

Vue.use(Router)

const routes = [
  {
    path: '/',
    component: Home,
    meta: { requiresAuth: true },
    children: [
      {
        path: '',
        name: 'Timeline',
        component: Timeline,
      },
      {
        path: 'activity',
        name: 'Activity',
        component: Activity,
      },
      {
        path: 'settings',
        name: 'Settings',
        component: Settings,
      },
      {
        path: 'user/:userid',
        name: 'Profile',
        component: Profile,
        beforeEnter: (to, from, next) => {
          // console.log(to, from, next)
          // Check if userid is a valid user, otherwise 404
          next();
        },
      },
      {
        path: 'post/:postid',
        name: 'Post',
        component: Post,
        beforeEnter: (to, from, next) => {
          // console.log(to, from, next)
          // Check if postid is a valid post, otherwise 404
          next();
        },
      },
    ],
  },
  {
    path: '/landing',
    component: Landing,
    children: [
      {
        path: '',
        name: 'GetStarted',
        component: GetStarted,
      },
      {
        path: '/privacy',
        name: 'PrivacyPolicy',
        component: () => import('../views/PrivacyPolicy.vue'),
      },
      {
        path: '/terms',
        name: 'TermsOfService',
        component: () => import('../views/TermsOfService.vue'),
      },
      {
        path: '/usage',
        name: 'FairUsage',
        component: () => import('../views/FairUsage.vue'),
      },
      {
        path: '*',
        name: '404',
        component: () => import('../views/404.vue'),
      },
    ]
  },
  {
    path: '/login',
    name: 'Authenticator',
    component: Authenticator
  }
];

const router = new Router({
  mode: 'history',
  routes
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    next({ path: '/landing' })
  }
  next()
})

export default router
