<template>
  <v-app app>
    <unauth-bar :color="color" :flat="flat" />
    <v-main>
      <router-view />
    </v-main>
    <v-scale-transition>
      <v-btn
        fab
        v-show="fab"
        v-scroll="onScroll"
        dark
        fixed
        bottom
        right
        color="secondary"
        @click="toTop"
      >
        <v-icon>mdi-arrow-up</v-icon>
      </v-btn>
    </v-scale-transition>
    <app-footer />
  </v-app>
</template>

<script>
import UnauthBar from "../components/Navigation/UnauthBar.vue";
import AppFooter from "../components/AppFooter"

export default {
  name: "landing",
  components: {
    UnauthBar,
    AppFooter,
  },
  created() {
    const top = window.pageYOffset || 0;
    if (top <= 60) {
      this.color = "transparent";
      this.flat = true;
    }
  },
  data() {
    return {
      fab: null,
      color: "",
      flat: null,
    };
  },
  methods: {
    onScroll(e) {
      if (typeof window === "undefined") return;
      const top = window.pageYOffset || e.target.scrollTop || 0;
      this.fab = top > 60;
    },
    toTop() {
      this.$vuetify.goTo(0);
    },
  },
  watch: {
    fab(value) {
      if (value) {
        this.color = "primary";
        this.flat = false;
      } else {
        this.color = "transparent";
        this.flat = true;
      }
    },
  },
};
</script>

<style scoped>

.v-main {
  padding: 0 !important;
  background-color: white;
  background-image: url("~@/assets/img/bgMain.png");
  background-attachment: fixed;
  background-position: center;
  background-size: cover;
}
</style>