import Vue from 'vue'
import UUID from "vue-uuid";
import VueMeta from 'vue-meta'
import VueYouTubeEmbed from 'vue-youtube-embed'

import App from './App.vue'
import router from './router'
import store from './state';

import vuetify from './plugins/vuetify';
import { firestorePlugin } from 'vuefire'

Vue.config.productionTip = false

Vue.use(UUID);
Vue.use(VueMeta)
Vue.use(VueYouTubeEmbed)
Vue.use(firestorePlugin)

new Vue({
  store,
  router,
  vuetify,
  render: h => h(App)
}).$mount('#app')
