
import firebase from "firebase";

import {
  SET_LOGIN_ERROR,
  SET_SIGNUP_ERROR,
  SET_RECOVER_PASSWORD_ERROR,
  SET_SIGNOUT_ERROR,
  SET_USER,
  SET_TIMELINE_POSTS
} from './mutation-types';

import { db } from '../db';
import { userExists } from '../services/database';

const actions = {
  async signUp(context, { email, password }) {
    firebase.auth().createUserWithEmailAndPassword(email, password)
      .then(async (userCredential) => {

        // Signed in 
        const user = userCredential.user;
        context.commit(SET_USER, user);

        // Add user to users collection
        db.collection("users").add({
          email: email,
          username: '',
          profilePicture: '',
          bio: '',
        })
          .then((docRef) => {
            console.log("Document written with ID: ", docRef.id);
          })
          .catch((error) => {
            console.error("Error adding document: ", error);
            context.commit(SET_SIGNUP_ERROR, error);
          });
      });
  },
  async logIn(context, { email, password }) {
    firebase.auth().signInWithEmailAndPassword(email, password)
      .then((userCredential) => {
        // Signed in 
        const user = userCredential.user;
        context.commit(SET_USER, user);
        // ...
      })
      .catch((error) => {
        context.commit(SET_LOGIN_ERROR, error);
      });
  },
  async logInWithGoogle({ commit }) {
    const provider = new firebase.auth.GoogleAuthProvider();
    provider.addScope('https://www.googleapis.com/auth/contacts.readonly');
    firebase.auth().signInWithPopup(provider)
      .then((result) => {
        // This gives you a Google Access Token. You can use it to access the Google API.
        // const credential = GoogleAuthProvider.credentialFromResult(result);
        // const token = credential.accessToken;

        // The signed-in user info.
        const user = result.user;

        const exists = userExists(user.email);
        if (!exists) {
          // Add user to users collection
          db.collection("users").add({
            email: user.email,
            username: user.displayName,
            profilePicture: user.photoURL,
            bio: '',
          })
            .then((docRef) => {
              console.log("Document written with ID: ", docRef.id);
            })
            .catch((error) => {
              console.error("Error adding document: ", error);
              commit(SET_SIGNUP_ERROR, error);
            });
        }

        commit(SET_USER, user);
      }).catch((error) => {
        // Handle Errors here.
        commit(SET_LOGIN_ERROR, error);

        // The email of the user's account used.
        // const email = error.email;
        // The AuthCredential type that was used.
        // const credential = GoogleAuthProvider.credentialFromError(error);
        // ...
      });

  },
  async recoverPassword({ commit }, email) {
    firebase.auth().sendPasswordResetEmail(email)
      .then(() => {
        // Email sent.
      }).catch((error) => {
        commit(SET_RECOVER_PASSWORD_ERROR, error)
        // An error happened.
      });
  },
  async signOut({ commit }) {
    firebase.auth().signOut().then(() => {
      // Sign-out successful.
    }).catch((error) => {
      // An error happened.
      commit(SET_SIGNOUT_ERROR, error)
    });
  },
  async getTimelinePosts({ commit, state }) {
    if (state.currentUser === null) {
      return;
    }

    commit(SET_TIMELINE_POSTS, { nextToken: null, posts: [] });
  },
  async deletePostById(context) {
    console.log(context)
  },
  downloadAudio(context, { sourceUrl, post }) {
    if (!sourceUrl || !post) {
      return;
    }

    console.log("download");
  },
};

export default actions;