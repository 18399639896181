
import { 
    SET_LOGIN_ERROR,
    SET_SIGNUP_ERROR,
    SET_RECOVER_PASSWORD_ERROR,
    SET_SIGNOUT_ERROR,
    SET_USER, 
    SET_CURRENT_CLIP, 
    SET_TIMELINE_POSTS, 
} from './mutation-types';

const mutations = {
    [SET_LOGIN_ERROR](state, payload) {
        state.logInError = payload;
    },
    [SET_SIGNUP_ERROR](state, payload) {
        state.signUpError = payload;
    },
    [SET_RECOVER_PASSWORD_ERROR](state, payload) {
        state.recoverPasswordError = payload;
    },
    [SET_SIGNOUT_ERROR](state, payload) {
        state.signOutError = payload;
    },
    [SET_USER](state, payload) {
        state.currentUser = payload;
    },
    [SET_CURRENT_CLIP](state, payload) {
        state.currentPlayingPostId = payload.currentPlayingPostId;
    },
    [SET_TIMELINE_POSTS](state, payload) {
        state.timelinePosts = payload.posts;
    },
};

export default mutations;