<template>
  <v-row class="container">
    <v-col cols="2">
      <v-row>
        <v-avatar class="rounded-lg" tile size="100%" color="grey">
          <v-img src="@/assets/default_avatar.png"></v-img>
        </v-avatar>
      </v-row>
    </v-col>
    <v-col>
      <v-row cols="8">
        <v-col justify="start" align="start" cols="5">
          <v-row class="font-weight-bold info-col post-title" color="accent">
            <div>
              {{ currentPostState.title }}
            </div>
          </v-row>
          <v-row class="info-col">
            <div class="link-to-profile">
              <router-link
                :to="{
                  name: 'Profile',
                  params: { userid: currentPostState.owner },
                }"
                class="grey--text text-subtitle1"
              >
                @{{ currentPostState.owner }}
              </router-link>
            </div>
          </v-row>
          <v-row justify="start" align="start" class="info-col">
              <span>
                <v-icon color="accent" medium @click="downloadAudio"
                  >mdi-download</v-icon
                >

                <!-- <span>{{ currentPostState.downloads }}</span> -->
              </span>
              <like-button
                v-bind:likesCount="currentPostState.likes"
                v-bind:postId="currentPostState.id"
                v-bind:showLikes="false"
              />
          </v-row>
        </v-col>
        <v-col cols="3">
          <v-btn
            class="ml-2 mt-3"
            icon
            right
            v-if="!playing"
            @click="togglePlay"
          >
            <v-icon large color="accent">mdi-play</v-icon>
          </v-btn>
          <v-btn class="ml-2 mt-3" fab icon right v-else @click="togglePlay">
            <v-icon large color="accent">mdi-pause</v-icon>
          </v-btn>
          <v-snackbar v-model="error" :timeout="errorTimeout">
            {{ errorText }}

            <template v-slot:action="{ attrs }">
              <v-btn color="pink" text v-bind="attrs" @click="error = false">
                Close
              </v-btn>
            </template>
          </v-snackbar>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import { mapState } from "vuex";
import { Howl } from "howler";

import LikeButton from "./Buttons/LikeButton";
import { SET_CURRENT_CLIP } from "../state/mutation-types";

export default {
  name: "discovery-item",
  props: ["post"],
  emits: ["play", "pause", "stop"],
  components: {
    LikeButton,
  },
  data() {
    return {
      playing: false,
      sourceUrl: "",
      error: false,
      errorText: "Error playing clip",
      errorTimeout: 2000,
      updateSubscription: null,
      currentPostState: this.post,
      seek: 0,
      updateSeek: null,
    };
  },
  async mounted() {
    this.sourceUrl = ""
    this.subscribeToAudioState();
  },
  beforeUnmount() {
    if (this.playing) {
      this.currentClip.stop();
    }
    this.unsubscribeFromAudioState();
  },
  computed: {
    ...mapState(["currentClip"]),
  },
  methods: {
    subscribeToAudioState() {
      this.unsubscribeFromAudioState = this.$store.subscribe((mutation) => {
        if (
          this.playing &&
          mutation.type === SET_CURRENT_CLIP &&
          mutation.payload.currentPlayingPostId !== this.post.id
        ) {
          this.$emit("pause");
          this.playing = false;
        }
      });
    },
    unsubscribeFromAudioState() {
      // you may call unsubscribe to stop the subscription
      this.unsubscribeFromAudioState();
    },
    togglePlay() {
      if (this.playing) {
        this.pauseLoop();
      } else {
        this.playLoop();
      }
    },
    playLoop() {
      this.$store.commit("SET_CURRENT_CLIP", {
        currentClip: new Howl({
          src: [this.sourceUrl],
          loop: true,
          autoplay: false,
          preload: true,
          onloaderror: () => {
            this.error = true;
            this.stopLoop();
          },
        }),
        currentPlayingPostId: this.post.id,
      });

      this.currentClip.seek(this.seek);

      this.$emit("play");
      this.currentClip.play();
      this.playing = true;
    },
    pauseLoop() {
      if (this.currentClip) {
        this.$emit("pause");
        this.currentClip.pause();
        this.playing = false;
      }
    },
    stopLoop() {
      if (this.currentClip) {
        this.$emit("stop");
        this.currentClip.stop();
        this.playing = false;
      }
    },
    async downloadAudio() {
      if (!this.sourceUrl) {
        return;
      }
    },
  },
  playing(playing) {
    if (this.currentClip !== null) {
      this.seek = this.currentClip.seek();
      if (playing) {
        this.updateSeek = setInterval(() => {
          if (this.currentClip !== null) {
            this.seek = this.currentClip.seek();
          }
        }, 250);
      } else {
        clearInterval(this.updateSeek);
      }
    }
  },
};
</script>

<style scoped>
a {
  text-decoration: none;
}

.link-to-profile:hover {
  text-decoration: underline;
}

.container {
  margin: 0.5em;
  padding: 0.5em;
}

.info-col {
  margin: 0.3em;
}

.post-title {
  font-size: 24px;
  font-family: 'Rubik', sans-serif;
}
</style>