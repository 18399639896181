
import colors from 'vuetify/lib/util/colors'

export default {
    primary: {
        base: "#000",
        lighten5: "#000",
        lighten4: "#000",
        lighten3: "#000",
        lighten2: "#000",
        lighten1: "#000",
        darken1: "#000",
        darken2: "#000",
        darken3: "#000",
        darken4: "#000",
    },
    secondary: "#6200EE",
    accent: "#FFF",
    error: colors.red.accent3,
    background: "#000",
}