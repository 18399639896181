<template>
  <section id="pricing">
    <v-container fluid>
      <v-row align="center" justify="center">
        <v-col cols="9">
          <v-card class="pricing-card" style="width: 100%" color="primary">
            <h1 class="text-center pt-6 font-weight-light display-2">
              Pricing
            </h1>
            <v-divider class="my-6"></v-divider>
            <v-row class="text-center" justify="space-around">
              <v-col class="col-12 col-sm-6 col-md-4">
                <div class="flex-center">
                  <v-card-text>
                    <div class="flex-center">
                      <div class="circle1">
                        <div class="circle2">
                          <v-img src="~@/assets/img/paperplane.svg"></v-img>
                        </div>
                      </div>
                    </div>
                    <div class="text-uppercase text-h5 my-2" color="secondary">
                      Starter
                    </div>
                    <v-divider class="my-2" />
                    <div class="text-uppercase" color="secondary">
                      Unlimited audio loops
                    </div>
                    <v-divider class="my-2" />
                    <div class="text-uppercase" color="secondary">
                      Customized feed of new artists to collaborate with
                    </div>
                    <v-divider class="my-2" />
                    <div class="text-uppercase" color="secondary">
                      Follow your favorite artists
                    </div>
                    <v-divider class="my-2" />
                    <div class="text-uppercase" color="secondary">
                      Share loops with friends
                    </div>
                    <v-divider class="my-2" />
                    <div class="text-uppercase text-h4 mt-6" color="secondary">
                      $ 0
                    </div>
                    <!-- <router-link to="/login">
                      <v-btn rounded color="secondary" class="mt-6">
                        Get Started
                      </v-btn>
                    </router-link> -->
                      <v-btn rounded color="secondary" class="mt-6" href="https://nzqgw10avlu.typeform.com/to/scNWQNtg">
                        Join the Waitlist
                      </v-btn>
                  </v-card-text>
                </div>
              </v-col>
              <v-divider
                style="margin-right: -23px"
                vertical
                v-if="this.$vuetify.breakpoint.smAndUp"
              ></v-divider>
              <v-col class="col-12 col-sm-6 col-md-4">
                <div class="flex-center">
                  <v-card-text>
                    <div class="flex-center">
                      <div class="circle1">
                        <div class="circle2">
                          <v-img src="~@/assets/img/airplane.svg" />
                        </div>
                      </div>
                    </div>
                    <div class="text-uppercase text-h5 my-2">Professional</div>
                    <v-divider class="my-2" />
                    <div class="text-uppercase" color="secondary">
                      Higher quality audio
                    </div>
                    <v-divider class="my-2" />
                    <div class="text-uppercase" color="secondary">No ads</div>
                    <v-divider class="my-2" />
                    <div class="text-uppercase" color="secondary">
                      Analytics for loops
                    </div>
                    <v-divider class="my-2" />
                    <div class="text-uppercase" color="secondary">
                      Upload private loops
                    </div>
                    <v-divider class="my-2" />
                    <div class="text-uppercase text-h4 mt-6" color="secondary">
                      $ 5.99 / month
                    </div>
                    <router-link to="/login">
                      <v-btn disabled rounded color="secondary" class="mt-6">
                        Coming Soon
                      </v-btn>
                    </router-link>
                  </v-card-text>
                </div>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <div class="svg-border-waves text-white">
      <v-img src="~@/assets/img/borderWavesBlue.svg" />
    </div>
  </section>
</template>


<script>
export default {
  name: "pricing",
};
</script>

<style lang=scss scoped>
ul {
  font-size: 13px;
  line-height: 1.5em;
  margin: 5px 0 15px;
  padding: 0;

  li {
    list-style: none;
    position: relative;
    padding: 0 0 0 20px;
  }

  li {
    &::before {
      content: "";
      position: absolute;
      left: 0;
      top: 5px;
      width: 10px;
      height: 10px;
      background-color: var(--v-primary-base);
      border-radius: 50%;
      -moz-border-radius: 50%;
      -webkit-eeborder-radius: 50%;
    }
  }
}

.circle1 {
  border-radius: 50%;
  width: 150px;
  height: 150px;
  background-color: #f0f8ff;
  display: flex;
  align-items: center;
  justify-content: center;
}

.circle2 {
  border-radius: 50%;
  width: 100px;
  height: 100px;
  background-color: #e0effc;
  display: flex;
  align-items: center;
  justify-content: center;
}

.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.svg-border-rounded svg {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  color: #f4f7f5;
  z-index: -1;
}

.content {
  z-index: 1;
}

svg {
  overflow: hidden;
}

section {
  position: relative;
}

.svg-border-waves .v-image {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 3rem;
  width: 100%;
  overflow: hidden;
}

.pricing-card {
  width: 100%;
  margin-bottom: 2.2em;
}

.v-application a {
  text-decoration: none;
  color: var(--v-accent-base) !important;
}
</style>

