<template>
  <section id="hero">
    <!-- <v-container>
      <v-row align="center">
        <v-col>
          <h1 class="display-2 header font-weight-bold mb-4">
            Showcase Your Creations
          </h1>
          <router-link to="/login">
            <v-btn link rounded large class="mt-5" color="secondary">
              Get started for Free
            </v-btn>
          </router-link>
        </v-col>
        <v-col>
          <v-img src="@/assets/Hero/Happy_Bunch_Desk.svg" />
        </v-col>
      </v-row>
    </v-container> -->
    <!-- <v-parallax src="@/assets/img/bgHero.jpg" height="750"> -->
    <v-parallax src="@/assets/BackgroundPurple.png" height="750">
      <v-row align="center" justify="center">
        <v-col cols="10">
          <v-row align="center" justify="center">
            <v-col cols="12" md="6" xl="8">
              <h1 class="font-weight-bold mb-4 landing-title">
                Collaboration <br />
                Made Easy 
              </h1>
              <h1 class="font-weight-light">
                The platform tailored for artists and producers <br />
                to collaborate and build great things
              </h1>
              <!-- <router-link to="/login">
                <v-btn link rounded large class="mt-5" color="secondary">
                  Get started for Free
                </v-btn>
              </router-link> -->
                <v-btn link rounded large class="mt-5" color="secondary" href="https://nzqgw10avlu.typeform.com/to/scNWQNtg">
                  Join the Waitlist
                </v-btn>
              <div class="video d-flex align-center py-4">
                <a @click.stop="dialog = true" class="playBut">
                  <svg
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    xmlns:a="http://ns.adobe.com/AdobeSVGViewerExtensions/3.0/"
                    x="0px"
                    y="0px"
                    width="60px"
                    height="60px"
                    viewBox="0 0 213.7 213.7"
                    enable-background="new 0 0 213.7 213.7"
                    xml:space="preserve"
                  >
                    <polygon
                      class="triangle"
                      id="XMLID_18_"
                      fill="none"
                      stroke-width="7"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-miterlimit="10"
                      points="73.5,62.5 148.5,105.8 73.5,149.1 "
                    />

                    <circle
                      class="circle"
                      id="XMLID_17_"
                      fill="none"
                      stroke-width="7"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-miterlimit="10"
                      cx="106.8"
                      cy="106.8"
                      r="103.3"
                    />
                  </svg>
                </a>
                <p class="subheading ml-2 mb-0">Demo Video</p>
              </div>
            </v-col>
            <v-col cols="12" md="6" xl="4" class="hidden-sm-and-down"> </v-col>
          </v-row>
        </v-col>
      </v-row>
      <div class="svg-border-waves text-white">
        <v-img src="@/assets/img/borderWaves.svg" />
      </div>
    </v-parallax>
    <v-container fluid id="features" class="mt-2">
      <v-row align="center" justify="center">
        <v-col cols="10">
          <v-row align="center" justify="space-around">
            <!-- <v-col cols="12" class="text-center">
              <h1 class="font-weight-light display-2">Title</h1>
              <h1 class="font-weight-light">
                Lorem ipsum dolor sit amet consectetur adipisicing elit.
              </h1>
            </v-col> -->
            <v-col
              cols="12"
              sm="4"
              class="text-center"
              v-for="(feature, i) in features"
              :key="i"
            >
              <v-hover v-slot:default="{ hover }">
                <v-card
                  color="white"
                  class="card"
                  shaped
                  :elevation="hover ? 10 : 4"
                  :class="{ up: hover }"
                >
                  <v-img
                    :src="feature.img"
                    max-width="100px"
                    class="d-block ml-auto mr-auto"
                    :class="{ 'zoom-effect': hover }"
                  ></v-img>
                  <h1 class="font-weight-regular black--text">{{ feature.title }}</h1>
                  <h4 class="font-weight-regular subtitle-1 black--text">
                    {{ feature.text }}
                  </h4>
                </v-card>
              </v-hover>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
    <v-dialog v-model="dialog" max-width="640px">
      <v-card>
        <youtube
          :video-id="videoId"
          @ready="ready"
          @playing="playing"
        ></youtube>
      </v-card>
    </v-dialog>
    <div class="svg-border-waves">
      <img src="~@/assets/img/wave2.svg" />
    </div>
  </section>
</template>

<script>
export default {
  name: "landing-home",
  data() {
    return {
      dialog: false,
      videoId: "7MVQglfhw0c",
      features: [
        {
          img: require("@/assets/img/icon1.png"),
          title: "Meet New Artists",
          text: "Whether you're looking for a vocalist, guitarist, or an EDM producer, you can find exactly what you need on In The Loop",
        },
        {
          img: require("@/assets/img/icon3.png"),
          title: "Showcase Your Work",
          text: "Expose your new samples to new artists and build a network or creators to work with",
        },
        {
          img: require("@/assets/img/icon2.png"),
          title: "Share Loops",
          text: "No more storing short clips on hard drives or Google Drive, let your friends see your new work here",
        },
      ],
    };
  },
  methods: {
    ready(event) {
      this.player = event.target;
    },
    playing(event) {
      // The player is playing a video.
      console.log(event);
    },
    change() {
      // when you change the value, the player will also change.
      // If you would like to change `playerVars`, please change it before you change `videoId`.
      // If `playerVars.autoplay` is 1, `loadVideoById` will be called.
      // If `playerVars.autoplay` is 0, `cueVideoById` will be called.
      this.videoId = "another video id";
    },
    stop() {
      this.player.stopVideo();
    },
    pause() {
      this.player.pauseVideo();
    },
  },
  watch: {
    dialog(value) {
      if (!value) {
        this.pause();
      }
    },
  },
};
</script>

<style scoped>
section {
  position: relative;
}

.header {
  margin-top: 15%;
}

.row {
  height: 100%;
}

.v-application a {
  text-decoration: none;
  color: var(--v-accent-base) !important;
}

.svg-border-waves .v-image {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 3rem;
  width: 100%;
  overflow: hidden;
}

.svg-border-waves img {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  margin-bottom: -2px;
  z-index: -1;
}

.zoom-effect {
  transform: scale(1.1);
}

.up {
  transform: translateY(-20px);
  transition: 0.5s ease-out;
}

.card {
  min-height: 300px;
  padding: 10px;
  transition: 0.5s ease-out;
}
.card .v-image {
  margin-bottom: 15px;
  transition: 0.75s;
}
.card h1 {
  margin-bottom: 10px;
}

#hero {
  z-index: 0;
}

.landing-title {
  font-size: 56px;
  line-height: 97%;
  font-family: 'Manrope', sans-serif !important;
}

.btn-play {
  transition: 0.2s;
}
</style>

<style lang="scss">
.circle {
  stroke: white;
  stroke-dasharray: 650;
  stroke-dashoffset: 650;
  -webkit-transition: all 0.5s ease-in-out;
  opacity: 0.3;
}
.playBut {
  /*  border: 1px solid red;*/
  display: inline-block;
  -webkit-transition: all 0.5s ease;
  .triangle {
    -webkit-transition: all 0.7s ease-in-out;
    stroke-dasharray: 240;
    stroke-dashoffset: 480;
    stroke: white;
    transform: translateY(0);
  }
  &:hover {
    .triangle {
      stroke-dashoffset: 0;
      opacity: 1;
      stroke: white;
      animation: nudge 0.7s ease-in-out;
      @keyframes nudge {
        0% {
          transform: translateX(0);
        }
        30% {
          transform: translateX(-5px);
        }
        50% {
          transform: translateX(5px);
        }
        70% {
          transform: translateX(-2px);
        }
        100% {
          transform: translateX(0);
        }
      }
    }
    .circle {
      stroke-dashoffset: 0;
      opacity: 1;
    }
  }
}
</style>
