<template>
  <v-card
    outlined
    :loading="loading"
    class="mx-auto upload rounded-xl"
    color="primary"
  >
    <v-card-title class="headline justify-center">
      Upload a New Loop
    </v-card-title>
    <v-row align="center">
      <v-col justify="center" align="center">
        <form class="upload-form">
          <v-text-field
            outlined
            color="accent"
            label="Loop Title"
            :rules="nameRules"
            v-model="selectedName"
          ></v-text-field>

          <v-file-input
            outlined
            color="accent"
            accept="audio/*"
            placeholder="Select clip"
            prepend-icon="mdi-file-music-outline"
            :rules="fileRules"
            @change="onFileChange"
          ></v-file-input>
          <uploaded-loop-data 
          v-on:duration="updateDuration"
          :file="this.selectedFile" />
          <v-btn
            color="secondary"
            class="lighten-1 white--text"
            elevation="2"
            raised
            :disabled="loading"
            @click="onupload"
          >
            <v-icon left> mdi-plus </v-icon>
            Post</v-btn
          >
        </form>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import { uuid } from "vue-uuid";

import UploadedLoopData from "../UploadedLoopData";

export default {
  name: "upload-card",
  components: {
    UploadedLoopData,
  },
  emits: ["uploaded"],
  data() {
    return {
      selectedName: "",
      selectedFile: null,
      nameRules: [
        (value) => !!value || "Required.",
        (value) => value.length < 10 || "Titles can't be over 10 characters",
      ],
      fileRules: [
        (value) => !!value || "Required.",
        () =>
          this.audioDuration <= this.maxDuration || "Loops can't be over 90secs",
      ],
      audioDuration: 0.0,
      maxDuration: 90, // 90 seconds
      loading: false,
    };
  },
  methods: {
    updateDuration(event) {
      this.audioDuration = event;
    },
    onFileChange(file) {
      this.selectedFile = file;
    },
    async onUpload() {
      this.loading = true;

      const fileName = this.selectedFile.name;
      const extension = this.selectedFile.type;
      console.log(extension);
      console.log(`uploading: ${fileName}`);

      const newFilename = uuid.v1();
      const output = fileName.split(".").pop();
      const newKey = newFilename + "." + output;
      console.log(newKey);

      this.selectedName = "";
      this.selectedFile = null;
      this.loading = false;

      this.$emit("uploaded");
    },
  },
};
</script>

<style scoped>
.v-application .primary {
  border-color: var(--v-accent-base) !important;
}

.upload-form {
  padding: 1% 1% 1% 1%;
}

.form-item {
  margin: 1em;
}

.upload {
  padding: 3em;
  margin: 0em;
  text-align: center;
}
</style>
