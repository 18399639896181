<template>
  <v-footer padless>
    <v-card flat tile class="primary white--text text-center footer-card">
      <v-card-text>
        <v-btn
          v-for="(icon, i) in icons"
          :key="i"
          class="mx-4 white--text"
          :href="icon.link"
          target="_blank"
          icon
        >
          <v-icon size="24px">{{ icon.text }}</v-icon>
        </v-btn>
      </v-card-text>

      <v-card-text class="white--text pt-0">
        Made with 💜 in Richmond, Virginia
      </v-card-text>

      <v-divider></v-divider>

      <v-card-text class="white--text">
        {{ new Date().getFullYear() }} — <strong>In The Loop LLC</strong>
      </v-card-text>
    </v-card>
  </v-footer>
</template>

<script>
export default {
  name: "app-footer",
  data() {
    return {
      icons: [
        {
          text: "mdi-twitch",
          link: "https://www.twitch.tv/itl_studios",
        },
        {
          text: "mdi-twitter",
          link: "https://twitter.com/itl_studios",
        },
        {
          text: "mdi-instagram",
          link: "https://www.instagram.com/itl_studios/",
        },
        {
          text: "mdi-discord",
          link: "https://discord.gg/ZwpPGx98Qc",
        },
        {
          text: "mdi-spotify",
          link: "https://open.spotify.com/show/6LQi6uQ5nEgIK9vo0am9PS",
        },
      ],
    };
  },
  methods: {},
};
</script>

<style scoped>
.footer-card {
  width: 100%;
}
</style>
