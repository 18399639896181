<template>
  <v-container>
    <v-row justify="space-between">
      <template v-if="!isXs">
        <v-col cols="7" class="posts">
          <template v-if="pageLoading">
            <v-progress-circular></v-progress-circular>
          </template>
          <template v-else>
            <v-row v-for="post in filteredPosts" :key="post.id">
              <large-audio-card :post="post" />
            </v-row>
            <div align="center" v-if="moreData">
              <v-btn class="more-button" color="secondary" @click="listPosts">
                More
              </v-btn>
            </div>
            <div class="end-of-list" align="center" v-else>End of List</div>
          </template>
        </v-col>
      </template>
      <template v-else>
        <v-col cols="11" class="posts">
          <template v-if="pageLoading">
            <v-progress-circular></v-progress-circular>
          </template>
          <template v-else>
            <v-row v-for="post in filteredPosts" :key="post.id">
              <large-audio-card v-bind:post="post" />
            </v-row>
            <div align="center" v-if="moreData && filteredPosts.length !== 0">
              <v-btn class="more-button" color="secondary" @click="listPosts">
                More
              </v-btn>
            </div>
            <div class="end-of-list" align="center" v-else>End of List</div>
          </template>
        </v-col>
      </template>
      <template v-if="!isXs">
        <v-col cols="4" class="discover-col">
          <div class="discover">
            <discover-card />
          </div>
        </v-col>
      </template>
    </v-row>
  </v-container>
</template>

<script>
import { mapState, mapActions } from "vuex";

import LargeAudioCard from "../components/Cards/LargeAudioCard.vue";
import DiscoverCard from "../components/Cards/DiscoverCard.vue";

export default {
  name: "home",
  components: {
    LargeAudioCard,
    DiscoverCard,
  },
  async mounted() {
    if (!this.pageLoading) {
      // Pull initial list
      await this.listPosts();

      // Subscribe for further updates
      this.subscribeToTimeline();

      console.log("BLAH", this.timelinePosts);
      console.log("FILTERED", this.filteredPosts)
    }
    this.onResize();
    window.addEventListener("resize", this.onResize, { passive: true });
  },
  unmount() {
    this.unsubscribeFromTimeline();
  },
  data() {
    return {
      timelineSubscription: null,
      nextTokens: [],
      moreData: true,
      isXs: false,
    };
  },
  computed: {
    ...mapState(["currentUser", "timelinePosts"]),
    pageLoading() {
      return this.currentUser === null;
    },
    // Temperary Fix to make sure there are no duplicates and no deleted posts
    filteredPosts() {
      return this.timelinePosts.filter((schema, index, self) => {
        return (index ===
          self.findIndex((obj) => {
            return obj.id === schema.id;
          })) && schema.deleted !== true;
      });
    },
  },
  methods: {
    ...mapActions({
      listPosts: "getTimelinePosts",
    }),
    onResize() {
      this.isXs = window.innerWidth < 850;
    },
    subscribeToTimeline() {
      // Subscribe to creation of Posts
      console.log("subscribe to the creation of posts")
    },
    unsubscribeFromTimeline() {
      this.timelineSubscription.unsubscribe();
    },
  },
  watch: {
    async pageLoading(value) {
      if (!value) {
        this.subscribeToTimeline();
        await this.listPosts();
      }
    },
  },
};
</script>

<style scoped>
.discover-col {
  padding-top: 0;
}

.discover {
  position: fixed;
  z-index: 1;
}

.more-button {
  margin-bottom: 5em;
}

.end-of-list {
  opacity: 0.65;
}
</style>
