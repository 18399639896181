<template>
  <v-card outlined color="primary" class="card rounded-xl">
    <v-row>
      <v-col justify="center" align="center">
        <div class="title">Discover</div>

        <v-divider class="title-divider" />

        <v-virtual-scroll
          :items="filteredPosts"
          :item-height="150"
          height="450"
          class="discover-list"
        >
          <template v-slot:default="{ item }">
            <v-row cols="12">
              <v-list-item :key="item.id">
                <v-list-item-content>
                  <discovery-item v-bind:post="item" />
                </v-list-item-content>
              </v-list-item>
            </v-row>
          </template>
        </v-virtual-scroll>
        <div align="center" v-if="moreData">
          <v-btn class="more-button" color="secondary" @click="listPosts">
            More
          </v-btn>
        </div>
        <div class="end-of-list" align="center" v-else>End of List</div>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>

import DiscoveryItem from "../DiscoveryItem.vue";

export default {
  name: "discover-card",
  components: {
    DiscoveryItem,
  },
  data() {
    return {
      posts: [],
      nextTokens: [],
      moreData: true,
    };
  },
  async mounted() {
    await this.listPosts();

    this.subscribeToPosts();
  },
  unmount() {
    this.unsubscribeFromPosts();
  },
  computed: {
    // Temperary Fix to make sure there are no duplicates and no deleted posts
    filteredPosts() {
      return this.posts.filter((schema, index, self) => {
        return (index === self.findIndex((obj) => {
            return obj.id === schema.id;
          })) && schema.deleted !== true;
      });
    },
  },
  methods: {
    subscribeToPosts() {
      // Subscribe to creation of Posts
      console.log("Subscribing to timeline");
    },
    unsubscribeFromposts() {
      this.postSubscription.unsubscribe();
    },
    async listPosts() {
      const res = ""

      this.nextTokens.unshift(res.data.listPostsSortedByTimestamp.nextToken);

      this.posts = this.posts.concat(res.data.listPostsSortedByTimestamp.items);
    },
  },
};
</script>

<style scoped>
.card {
  height: 37em;
  width: 25em;
  /* border-radius: 25px; */
}

.v-application .primary {
  border-color: var(--v-accent-base) !important;
}

.title {
  margin: 1em;
}

.title-divider {
  width: 80%;
  border-color: var(--v-accent-base) !important;
}

.discover-list {
  margin-top: 1em;
}
</style>