
import Vue from 'vue';
import Vuex from 'vuex';

import actions from './actions';
import mutations from './mutations';

// import auth from './modules/auth';
// import audio from './modules/audio';

Vue.use(Vuex);

const state = {
  logInError: null,
  signUpError: null,
  recoverPasswordError: null,
  signOutError: null,
  currentUser: null,
  currentPlayingPostId: "",
  timelinePosts: [],
};

export default new Vuex.Store({
  state,
  actions,
  mutations,
})

/*
export default new Vuex.Store({
  modules: {
    cart,
    products
  },
  strict: debug,
  plugins: debug ? [createLogger()] : []
})
*/