<template>
  <v-app app>
    <v-container>
      <v-row justify="center" align="center">
        <v-col justify="center" align="center">
          <login />
        </v-col>
      </v-row>
    </v-container>
  </v-app>
</template>

<script>
import { mapActions, mapState } from "vuex";
import Login from "../components/Auth/Login";

export default {
  name: "Authenticator",
  components: { Login },
  data() {
    return {};
  },
  computed: {
    ...mapState(["logInError", "signUpError", "recoverPasswordError"]),
  },
  methods: {
    ...mapActions(["logIn", "logInWithGoogle", "signUp", "recoverPassword"]),
    _logIn() {
      this.logIn();
    },
    _logInWithGoogle() {
      this.logInWithGoogle();
    },
    _signUp() {
      this.signUp();
    },
    _recoverPassword() {
      this.recoverPassword();
    },
  },
};
</script>

<style scoped>
.survey-link {
  color: rgb(187, 0, 187);
  font-weight: bold;
  text-decoration: none;
}
</style>